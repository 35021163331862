import Vue from 'vue'
import { Message, MessageBox } from 'element-ui'

import { LIMIT_TYPES, PROMOTION_TYPES, SHORT_PROMOTION_TYPES, REGION_TYPES, IS_PRODUCTION_SITE } from '~/settings'

import SampleRequestModal from '~/components/modals/SampleRequestModal'
import HeadBuyerSampleRequestModal from '~/components/modals/HeadBuyerSampleRequestModal'
import PurchaseRequestModal from '~/components/modals/PurchaseRequestModal'
import PreOrderModal from '~/components/modals/PreOrderModal'
import MoqAlertModal from '~/components/modals/MoqAlertModal'
import MovAlertModal from '~/components/modals/MovAlertModal'
import MovAlertStrictedModal from '~/components/modals/MovAlertStrictedModal'
import OrderPlacedModal from '~/components/modals/OrderPlacedModal'
import ReOrderModal from '~/components/modals/ReOrderModal'
import PreviewProductModal from '~/components/modals/PreviewProductModal'

// import VendorCapitalModal from '~/components/modals/VendorCapitalModal'
import VendorShopifyModal from '~/components/modals/VendorShopifyModal'
import VendorRetailerReferralModal from '~/components/modals/VendorRetailerReferralModal'
import BuyerAppModal from '~/components/modals/BuyerAppModal'
import PrivacyPolicyModal from '~/components/modals/PrivacyPolicyModal'
import ConsignmentInstructionsModal from '~/components/modals/ConsignmentInstructionsModal'
import WithdrawalGeneralInstructionsModal from '~/components/modals/WithdrawalGeneralInstructionsModal'
import ProductSkusSelector from '~/components/modals/ProductSkusSelector'
import FulfillmentOrderModal from '~/components/modals/FulfillmentOrderModal'
import DeliveryInstructionModal from '~/components/modals/DeliveryInstructionModal'
import CardEditor from '~/components/modals/CardEditor'

class SilentError extends Error {}

export default (context, inject) => {
  const { app, store, isDev, route } = context

  // new
  const notify = (message, options = {}) => {
    return Message({
      message,
      type: 'success',
      showClose: true,
      offset: 110,
      ...options
    })
  }

  const error = e => {
    // eslint-disable-next-line no-console
    if (!IS_PRODUCTION_SITE) console.error(e)
    if (e instanceof SilentError) return
    return notify(e, {
      type: 'error'
    })
  }

  const warning = e => {
    // eslint-disable-next-line no-console
    if (!IS_PRODUCTION_SITE) console.error(e)
    if (e instanceof SilentError) return
    return notify(e, {
      type: 'warning'
    })
  }

  const isInPrintLayout = () => {
    const layout = app.context.nuxtState.layout
    return layout === 'print'
  }

  const openProductPreview = ({ parent, product }) => {
    return app.$dialogs
      .open({
        component: PreviewProductModal,
        parent,
        props: {
          product
        }
      })
  }

  const requestPreOrder = ({ parent, product }) => {
    return app.$dialogs
      .open({
        component: PreOrderModal,
        parent,
        props: {
          product
        }
      })
      .then(res => notify('Preorder has been sent!'))
  }

  const requestSample = ({ parent, product }) => {
    const isHeadBuyer = store.getters['authentication/isHeadBuyer']
    const component = isHeadBuyer ? HeadBuyerSampleRequestModal : SampleRequestModal

    return app.$dialogs
      .open({
        component,
        parent,
        props: {
          product
        }
      })
      .then(res => notify('Request has been sent!'))
  }

  const requestToPurchase = ({ parent, product }) => {
    return app.$dialogs
      .open({
        component: PurchaseRequestModal,
        parent,
        props: {
          product
        }
      })
      .then(res => notify('Thanks for making a purchase request! A Pod Foods representative will reach out to you within 24 hours. If you have any questions, please email <a href="mailto:hi@podfoods.co" target="_blank">hi@podfoods.co</a>', {
        dangerouslyUseHTMLString: true
      }))
  }

  const confirmShortDatePromotionIfNeeded = ({ variants = [], message = 'This product is not a guaranteed sale and there are no refunds for expiration of this item.' } = {}) => {
    const hasShortDatePromotion = variants
    .filter(i =>
      i &&
      i.promotions &&
      i.promotions[0] && (
      i.promotions[0].promotion_type === PROMOTION_TYPES.SHORT_DATE ||
      i.promotions[0].promotion_type === SHORT_PROMOTION_TYPES.SHORT_DATE)
    ).length > 0
    if (hasShortDatePromotion)
      return MessageBox
        .confirm(message, 'Confirm', {
          confirmButtonText: 'I understand',
          cancelButtonText: 'Cancel'
        })
    return Promise.resolve()
  }

  const notifyAddedCartItem = ({ variant, quantity, topmost } = {}) => {
    notify(
      quantity > 1
        ? 'Items added to cart!'
        : 'Item added to cart!',
      {
        customClass: topmost ? 'el-message--topmost' : undefined
      }
    )
    return Promise.resolve()
  }

  const notifyAddedCartItems = ({ variants, cartItems = [], topmost = false } = {}) => {
    notify(
      cartItems.length > 1
        ? 'Items added to cart!'
        : 'Item added to cart!',
      {
        customClass: topmost ? 'el-message--topmost' : undefined
      }
    )
    return Promise.resolve()
  }

  const addMoqItemToCart = ({ parent, product, variant, quantity, preOrderItems, events } = {}) => {
    // add to cart runnable
    const submit = () =>
      confirmShortDatePromotionIfNeeded({ variants: [variant] })
        .then(() => store.dispatch('cart/addItems', {
          cart_items: [{
            quantity,
            variants_region_id: variant.variants_region_id,
            pre_order_line_item_id: variant.pre_order_line_item_id
          }]
        }))
    // moq check
    const addedQuantity = store.getters['cart/addedQuantityByProduct']({ variant })
    const estimatedQuantity = addedQuantity + quantity
    const moq = (product.products_region_moqs || [])
      .find(i => i.region_id === variant.region_id)?.moq || 1
    if (estimatedQuantity < moq) {
      switch (variant.region_type) {
        case REGION_TYPES.PFD:
          return confirmShortDatePromotionIfNeeded({ variants: [variant] })
            .then(() => {
              return showMoqAlert({
                parent,
                product,
                variant,
                quantity,
                preOrderItems,
                incomingPfdItem: { ...variant, incomingQuantity: quantity },
                events
              })
            })
        case REGION_TYPES.WAREHOUSED:
          return submit()
            .then(() => {
              notifyAddedCartItem({ variant, quantity, topmost: true })
              return showMoqAlert({ parent, product, variant, quantity, preOrderItems, added: quantity, events })
            })
      }
    }
    return submit()
      .then(() => notifyAddedCartItem({ variant, quantity }))
  }

  const showMoqAlert = ({ parent, events, ...props } = {}) => {
    return app.$dialogs.open({
      component: MoqAlertModal,
      parent,
      events,
      props
    })
  }

  const addMovItemToCart = ({ parent, product, variant, quantity, preOrderItems, events } = {}) => {
    return confirmShortDatePromotionIfNeeded({ variants: [variant] })
      .then(() => {
        if (variant.region_type === REGION_TYPES.PFD && !variant.is_pod_grow_variant) {
          const addedValue = store.getters['cart/addedValueByVendorCompany']({ variant })
          const estimatedValue = addedValue + quantity * variant.case_price_cents
          if (estimatedValue >= variant.mov) return Promise.resolve({ movAlertUpdatedCart: false })
          return showMovAlert({
            parent,
            product,
            incomingPfdItems: [{ ...variant, incomingQuantity: quantity }],
            variant,
            quantity,
            preOrderItems,
            events
          }).then(() => Promise.resolve({ movAlertUpdatedCart: true }))
        }
        // If only add Express MOV item(s)
        return Promise.resolve({ movAlertUpdatedCart: false })
      })
      .then(({ movAlertUpdatedCart }) => {
        if (movAlertUpdatedCart) return Promise.resolve()
        return store.dispatch('cart/addItems', {
          cart_items: [{
            quantity,
            variants_region_id: variant.variants_region_id,
            pre_order_line_item_id: variant.pre_order_line_item_id
          }]
        }).then(() => notifyAddedCartItem({ variant, quantity }))
      })
  }

  const addMultipleItemToCart = ({ parent, product, variants, cartItems, events } = {}) => {
    return confirmShortDatePromotionIfNeeded({ variants, message: 'SKUs with short-dated promotions are not guaranteed sales and there are no refunds for expiration of these items' })
      .then(() => {
        const pfdMovVariants = variants.filter((i) => i.region_type === REGION_TYPES.PFD && i.limit_type === LIMIT_TYPES.MOV && !i.is_pod_grow_variant)
        const expressMovVariants = variants.filter((i) => i.region_type === REGION_TYPES.WAREHOUSED && i.limit_type === LIMIT_TYPES.MOV && !i.is_pod_grow_variant)
        if (pfdMovVariants.length > 0) {
          const variant = { ...pfdMovVariants[0] }
          const addedValue = store.getters['cart/addedValueByVendorCompany']({ variant })
          const incomingPfdItems = pfdMovVariants.map(v => ({
            ...v,
            incomingQuantity: cartItems.find(i => +i.variants_region_id === +v.variants_region_id)?.quantity
          }))
          const incomingValue = incomingPfdItems.reduce((sum, i) => sum + i.incomingQuantity * i.case_price_cents, 0)
          const estimatedValue = addedValue + incomingValue

          const movs = store.getters['cart/movs'] || []
          const unreached = movs.filter(i => i.limitType === LIMIT_TYPES.MOV && !i.reach && product.vendor_company_id === i.company)
          const pfdCompanyInCart = movs.find(i => i.company === variant.vendor_company_id)

          if (estimatedValue >= variant.mov || (pfdCompanyInCart && !unreached.length)) {
            return Promise.resolve({ movAlertUpdatedCart: false })
          }

          // If it has both of mov (pfd & express) items, then we'll add mov express items to cart first.
          if (expressMovVariants.length > 0) {
            const expressMovVariantRegionIds = expressMovVariants.map(i => i.variants_region_id)
            const expressCartItems = cartItems.filter(i => expressMovVariantRegionIds.includes(+i.variants_region_id))
            store.dispatch('cart/addItems', { cart_items: expressCartItems })
              .then(() => notifyAddedCartItems({ variants, expressCartItems, topmost: true }))
          }
          return showMovAlert({
            parent,
            product,
            incomingPfdItems,
            mov: unreached[0] || null,
            variant: unreached[0]?.items?.[0] || variant,
            quantity: 0,
            events
          }).then(() => Promise.resolve({ movAlertUpdatedCart: true }))
        }
        // If only add Express MOV item(s) or any MOQ item(s)
        return Promise.resolve({ movAlertUpdatedCart: false })
      })
      .then(({ movAlertUpdatedCart }) => {
        if (movAlertUpdatedCart) return Promise.resolve()
        return store.dispatch('cart/addItems', { cart_items: cartItems }).then(() => notifyAddedCartItems({ variants, cartItems }))
      })
  }

  const showMovAlert = ({ parent, events, ...props } = {}) => {
    return app.$dialogs.open({
      component: MovAlertModal,
      parent,
      events,
      props
    })
  }

  const showMovStrictedAlertModal = ({ parent, events, ...props } = {}) => {
    return app.$dialogs.open({
      component: MovAlertStrictedModal,
      parent,
      events,
      props
    })
  }

  const addItemToCart = ({ parent, product, variant, quantity, preOrderItems, events } = {}) => {
    switch (variant.limit_type) {
      case LIMIT_TYPES.MOQ:
        return addMoqItemToCart({
          parent,
          product,
          variant,
          quantity,
          preOrderItems,
          events
        })
      case LIMIT_TYPES.MOV:
        return addMovItemToCart({
          parent,
          product,
          variant,
          quantity,
          preOrderItems,
          events
        })
      default:
        return Promise.reject(new Error('Unknown product variant limit type'))
    }
  }

  const addItemsToCart = ({ variants, cartItems } = {}) => {
    return confirmShortDatePromotionIfNeeded({ variants, message: 'SKUs with short-dated promotions are not guaranteed sales and there are no refunds for expiration of these items' })
      .then(() => {
        return store
          .dispatch('cart/addItems', {
            cart_items: cartItems
          })
          .then(() => notifyAddedCartItems({ variants, cartItems }))
      })
  }

  // eslint-disable-next-line
  const checkout = ({ parent, data } = {}) => {
    return store.dispatch('cart/checkout', data)
      .then(res => {
        app.$dialogs.open({
          parent,
          component: OrderPlacedModal,
          props: {
            order: {
              id: res.order_id
            }
          }
        })
        return res
      })
      .catch(e => {
        notify('Could not check out your cart now.</br>Please try again later!', {
          type: 'error',
          dangerouslyUseHTMLString: true
        })
      })
  }

  // const showVendorCapitalInvitationIfNeeded = ({ parent } = {}) => {
  //   if (
  //     isInPrintLayout() ||
  //     ['capital'].includes(route.name)
  //   ) return Promise.resolve()
  //   const isVendor = store.getters['authentication/isVendor']
  //   const hidden = app.$preferences.get('$hideVendorCapitalInvitation', false)
  //   if (!isVendor || hidden) return Promise.resolve()
  //   return app.$dialogs
  //     .open({
  //       destroyOnRouteChanged: false,
  //       parent,
  //       component: VendorCapitalModal
  //     })
  //     .then(shouldBeHidden => {
  //       app.$preferences.set('$hideVendorCapitalInvitation', shouldBeHidden)
  //     })
  // }

  const showVendorShopifyInvitationIfNeeded = ({ parent } = {}) => {
    if (
      isInPrintLayout() ||
      ['capital'].includes(route.name)
    ) return Promise.resolve()
    const isVendor = store.getters['authentication/isVendor']
    const isBroker = store.getters['authentication/isBroker']
    const hidden = app.$preferences.get('$hideVendorShopifyInvitation', false)
    if (!isVendor || isBroker || hidden) return Promise.resolve()
    return app.$dialogs
      .open({
        destroyOnRouteChanged: false,
        parent,
        component: VendorShopifyModal
      })
      .then(shouldBeHidden => {
        app.$preferences.set('$hideVendorShopifyInvitation', shouldBeHidden)
      })
  }

  const showVendorRetailerReferralInvitationIfNeeded = ({ parent } = {}) => {
    if (
      isInPrintLayout() ||
      ['referral'].includes(route.name)
    ) return Promise.resolve()
    const isVendor = store.getters['authentication/isVendor']
    const hidden = app.$preferences.get('$hideVendorRetailerReferralInvitation', false)

    if (!isVendor || hidden) return Promise.resolve()
    return app.$dialogs
      .open({
        destroyOnRouteChanged: false,
        parent,
        component: VendorRetailerReferralModal
      })
      .then(shouldBeHidden => {
        app.$preferences.set('$hideVendorRetailerReferralInvitation', shouldBeHidden)
      })
  }

  const showBuyerAppInvitationIfNeeded = ({ parent } = {}) => {
    if (isInPrintLayout()) return Promise.resolve()
    const isBuyer = store.getters['authentication/isBuyer']
    const isHeadBuyer = store.getters['authentication/isHeadBuyer']
    const isApprovedCompany = store.getters['authentication/isApprovedCompany']
    const hidden = app.$preferences.get('$hideBuyerAppInvitation', false)

    if (!isHeadBuyer && !isBuyer) return Promise.resolve()
    if (!isApprovedCompany || hidden) return Promise.resolve()

    return app.$dialogs
      .open({
        destroyOnRouteChanged: false,
        parent,
        component: BuyerAppModal
      })
      .then(shouldBeHidden => {
        app.$preferences.set('$hideBuyerAppInvitation', shouldBeHidden)
      })
  }

  const showPrivacyPolicyChangesIfNeeded = () => {
    if (isInPrintLayout()) return Promise.resolve()
    const isBroker = store.getters['authentication/isBroker']
    const termAgreed = store.getters['authentication/didAgreeToTerms']
    const policyAgreed = store.getters['authentication/didAgreeToPolicies']
    const reviewing = store.getters['authentication/isReviewingTerms']
    const isApprovedCompany = store.getters['authentication/isApprovedCompany']
    const setReviewingMode = isReviewingTerms => store.commit('authentication/update', { isReviewingTerms })

    if (isBroker) return Promise.resolve()

    if (reviewing || !isApprovedCompany) return Promise.resolve()

    if (policyAgreed && termAgreed) {
      setReviewingMode(false)
      return Promise.resolve()
    }

    setReviewingMode(true)

    return app.$dialogs
      .open({
        destroyOnRouteChanged: false,
        component: PrivacyPolicyModal,
        props: {
          api: app.$api,
          termAgreed,
          policyAgreed
        }
      })
      .then(agreed => {
        store.commit('authentication/agreeToTerms', agreed)
      })
      .finally(() => {
        setReviewingMode(false)
      })
  }

  const showConsignmentInstructions = ({ parent }) => {
    return app.$dialogs
      .open({
        parent,
        component: ConsignmentInstructionsModal
      })
  }

  const showWithDrawalGeneralInstructions = ({ parent }) => {
    return app.$dialogs
      .open({
        parent,
        component: WithdrawalGeneralInstructionsModal
      })
  }

  const selectProductSkus = ({ parent, ...args }) => {
    return app.$dialogs
      .open({
        parent,
        component: ProductSkusSelector,
        ...args
      })
  }

  const requestReOrder = ({ parent, lineItems }) => {
    return app.$dialogs
      .open({
        component: ReOrderModal,
        parent,
        props: {
          lineItems
        }
      })
  }

  const openFulfillmentOrderModal = ({ parent, items, invoice, order, type }) => {
    return app.$dialogs.open({
      component: FulfillmentOrderModal,
      parent,
      props: {
        items,
        order,
        invoice,
        type
      }
    })
  }

  const openDeliveryInstructionModal = ({ parent, order, invoice }) => {
    return app.$dialogs.open({
      component: DeliveryInstructionModal,
      parent,
      props: {
        order,
        invoice
      }
    })
  }

  const addCreditCard = ({ parent, showNotice = false } = {}) => {
    return app.$dialogs
      .open({
        parent,
        component: CardEditor,
        props: {
          showNotice
        }
      })
  }

  const workflow = {
    notify,
    notifyAddedCartItem,
    error,
    warning,
    requestPreOrder,
    requestSample,
    requestToPurchase,
    requestReOrder,
    openProductPreview,
    addItemToCart,
    addItemsToCart,
    addMultipleItemToCart,
    showMovAlert,
    showMovStrictedAlertModal,
    checkout,
    // showVendorCapitalInvitationIfNeeded,
    showVendorShopifyInvitationIfNeeded,
    showVendorRetailerReferralInvitationIfNeeded,
    showBuyerAppInvitationIfNeeded,
    showPrivacyPolicyChangesIfNeeded,
    showConsignmentInstructions,
    showWithDrawalGeneralInstructions,
    selectProductSkus,
    openFulfillmentOrderModal,
    openDeliveryInstructionModal,
    addCreditCard,
    confirmShortDatePromotionIfNeeded
  }

  if (isDev) {
    Vue.prototype.$workflow = workflow
    app.$workflow = workflow
    store.$workflow = workflow
  }

  inject('workflow', workflow)

  store.dispatch('nuxtClientInit', context)
}
